import React, { useEffect } from "react";
import CloseIcon from "@mui/icons-material/Close";
import "./AlertsView.css";
import EpochSwitch from "./EpochSwitch";
import Paper from "@mui/material/Paper";
import InputBase from "@mui/material/InputBase";
import { useAuth } from "../../../../authContext";
import { updateUserDetails } from "../Api";
import InnerLoader from "components/Auth/inner-loader";
import { useState } from "react";
import EpochTooltip from "../EpochTooltip";

function AlertsView({ setAlertsOpen, alertsOpen }) {
  const { user, setUser } = useAuth();
  const [hide, setHide] = useState(false);
  const [loading, setLoading] = useState(false);
  const [settings, setSettings] = useState(
    user?.user_data.settings?.ALERT_SETTINGS ?? {}
  );
  const [timerId, setTimerId] = useState(null);

  useEffect(() => {
    if (!alertsOpen) {
      setTimeout(() => {
        setHide(true);
      }, 300);
    } else {
      setHide(false);
    }
  }, [alertsOpen]);

  const updateAlerts = async (newSettings) => {
    if (
      JSON.stringify(newSettings) !==
      JSON.stringify(user?.user_data.settings?.ALERT_SETTINGS)
    ) {
      setLoading(true);
      setUser((prevUser) => ({
        ...prevUser,
        user_data: {
          ...prevUser.user_data,
          settings: {
            ...prevUser.user_data.settings,
            ALERT_SETTINGS: newSettings,
          },
        },
      }));
      await updateUserDetails(newSettings);
      setLoading(false);
    }
  };

  const handleToggle = (type, property) => {
    console.log(type, !settings[type][property]);
    const newState = {
      ...settings,
      [type]: { ...settings[type], [property]: !settings[type][property] },
    };

    setSettings(() => newState);

    // Debounce the updateAlerts function
    if (timerId) clearTimeout(timerId);
    const newTimerId = setTimeout(() => {
      updateAlerts(newState);
    }, 1000);
    setTimerId(newTimerId);
  };

  const handleInputChange = (type, key, value) => {
    const newState = {
      ...settings,
      [type]: { ...settings[type], [key]: value },
    };
    setSettings(() => newState);

    // Debounce the updateAlerts function
    if (timerId) clearTimeout(timerId);
    const newTimerId = setTimeout(() => {
      updateAlerts(newState);
    }, 1000);
    setTimerId(newTimerId);
  };

  const getInputData = (type) => {
    const data = {
      SMS: {
        placeholder: "Your phone number",
        key: "phone_numbers",
        validator: () => true,
        validationMessage: "",
        value: settings.SMS?.phone_numbers,
      },
      EMAIL: {
        placeholder: "Your email address",
        key: "email_addresses",
        validator: (email) => {
          const regex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
          return regex.test(email);
        },
        validationMessage: "Invalid email address",
        value: settings.EMAIL?.email_addresses,
      },
      SLACK: {
        placeholder: "Your slack webhook",
        key: "webhooks",
        validator: (url) => {
          return url.includes("https://");
        },
        validationMessage: "URL must contain https://",
        value: settings.SLACK?.webhooks,
      },
      TEAMS: {
        placeholder: "Your teams webhook",
        key: "webhooks",
        validator: (url) => {
          return url.includes("https://");
        },
        validationMessage: "URL must contain https://",
        value: settings.TEAMS?.webhooks,
      },
      WEBHOOK: {
        placeholder: "Other webhook e.g., zapier",
        key: "webhooks",
        validator: (url) => {
          return url.includes("https://");
        },
        validationMessage: "URL must contain https://",
        value: settings.WEBHOOK?.webhooks,
      },
    };
    return data[type];
  };

  return (
    <div
      className={`alerts-view-backdrop ${
        !alertsOpen && "opacity-alerts-view-backdrop"
      } ${hide && "hidden-alerts-view-backdrop"}`}
    >
      <div className="alerts-view-modal">
        <InnerLoader loading={loading} message="Saving changes..." />
        <CloseIcon
          className="alerts-view-close-icon"
          fontSize="large"
          onClick={() => setAlertsOpen(false)}
        />
        <h1 className="alerts-view-title">Configure Alerts</h1>
        <div className="alerts-view-row">
          <div className="alerts-view-column-left">
            <div className="alerts-view-label-top"></div>

            {Object.keys(settings).map((type) => (
              <div className="alerts-view-label">{type}</div>
            ))}
          </div>
          <div className="alerts-view-column">
            <div className="alerts-view-label-top">
              <EpochTooltip
                tooltip={"This is Send Summaries tooltip"}
                label={"Send Summaries"}
              />
            </div>
            {Object.keys(settings).map((type) => (
              <EpochSwitch
                key={type}
                value={settings[type].summaries}
                onChange={() => handleToggle(type, "summaries")}
              />
            ))}
          </div>

          <div className="alerts-view-column">
            <div className="alerts-view-label-top">
              <EpochTooltip
                tooltip={"This is Send Alerts tooltip"}
                label={"Send Alerts"}
              />
            </div>
            {Object.keys(settings).map((type) => {
              return (
                <EpochSwitch
                  key={type}
                  value={settings[type].enabled}
                  onChange={() => handleToggle(type, "enabled")}
                />
              );
            })}
          </div>
          <div className="alerts-view-column">
            <div className="alerts-view-label-top">
              <EpochTooltip
                tooltip={"This is Send To tooltip"}
                label={"Send To"}
              />
            </div>
            {Object.keys(settings).map((type) => {
              const { placeholder, value, key, validator, validationMessage } =
                getInputData(type);
              return (
                <Paper
                  className={`documents-view-input ${
                    !validator(settings[type][key]) && "error"
                  }`}
                  key={type}
                >
                  <InputBase
                    placeholder={placeholder}
                    value={value || ""}
                    onChange={(e) =>
                      handleInputChange(type, key, e.target.value)
                    }
                    error={true}
                  />
                  {!validator(settings[type][key]) && (
                    <div className="documents-view-input-error">
                      {validationMessage}
                    </div>
                  )}
                </Paper>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
}

export default AlertsView;
