import React, { useEffect, useCallback, useRef } from "react";
import particlesConfig from "config/particle-config";
import particlesBlackConfig from "config/pr-s-black";
import Particles from "react-tsparticles";
import { loadFull } from "tsparticles";
import { Link } from "gatsby";
import "./intro4.css";

const Intro4 = ({ sliderRef, blackStar }) => {
  const videoRef = useRef(null);

  useEffect(() => {
    setTimeout(() => {
      document
        .querySelector("#particles-js canvas")
        ?.style.removeProperty("position");
    }, 500);

    if (videoRef.current) {
      videoRef.current.play().catch((error) => {
        console.error("Video playback failed:", error);
      });
    }
  }, []);

  const particlesInit = useCallback(async (engine) => {
    await loadFull(engine);
    document
      .querySelector("#particles-js canvas")
      ?.style.removeProperty("position");
  }, []);

  return (
    <header ref={sliderRef} className="particles circle-bg valign">
      <div className="container intro-container">
        <div className="row justify-content-center">
          <div className="col-lg-10">
            <div className="cont text-center">
              <h6 className="wow color-font fadeInUp" data-wow-delay=".4s">
                ALPHA SIGNALS PLATFORM
              </h6>
              <h1 className="wow fadeInUp" data-wow-delay=".4s">
                Trade news faster <br /> & smarter
              </h1>
              <video
                ref={videoRef}
                width="100%"
                height="auto"
                muted
                loop
                playsInline
                className="supademo-video-wrapper wow fadeInUp"
                data-wow-delay=".4s"
              >
                <source
                  src="https://public.platform.9epoch.ai/public/platform_frontpage.mp4"
                  type="video/mp4"
                />
              </video>
              <h5 className="wow fw-200 fadeInUp" data-wow-delay=".4s">
                Get realtime market impact alerts & summaries from news, broker
                research or any other data source
              </h5>
              <div className="container">
                <div className="hero-action-buttons justify-content-center">
                  <div>
                    <div className="cont text-center">
                      <Link
                        to={`/how-it-works`}
                        className="butn bord curve mt-40 wow fadeInUp hero-action-button"
                        data-wow-delay=".4s"
                        style={{ position: "relative", zIndex: 1 }}
                      >
                        <span>How It Works</span>
                      </Link>
                    </div>
                  </div>
                  <div>
                    <div className="cont text-center">
                      <Link
                        to={`/auth/`}
                        className="butn bord curve mt-40 wow fadeInUp hero-action-button"
                        data-wow-delay=".4s"
                        state={{ signUp: true }}
                        style={{ position: "relative", zIndex: 1 }}
                      >
                        <span>Get Started For Free</span>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Particles
        id="particles-js"
        init={particlesInit}
        options={blackStar ? particlesBlackConfig : particlesConfig}
      />
      <div className="gradient-circle"></div>
      <div className="gradient-circle two"></div>
    </header>
  );
};

export default Intro4;

{
  /* <div
                className="supademo-wrapper"
                style={{
                  position: "relative",
                  height: 0,
                  width: "100%",
                  maxWidth: "800px",
                }}
              >
                <iframe
                  src="https://app.supademo.com/embed/clxvk42e308f5dd9219mdh7ff"
                  className="supademo"
                  title="Platform Frontpage"
                  allow="clipboard-write"
                  webkitAllowFullScreen
                  mozAllowFullScreen
                  allowFullScreen
                  style={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    width: "100%",
                    height: "100%",
                    border: "none",
                  }}
                ></iframe>
              </div> */
}
