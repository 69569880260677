exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-about-dark-jsx": () => import("./../../../src/pages/about/about-dark.jsx" /* webpackChunkName: "component---src-pages-about-about-dark-jsx" */),
  "component---src-pages-auth-activate-account-jsx": () => import("./../../../src/pages/auth/activate-account.jsx" /* webpackChunkName: "component---src-pages-auth-activate-account-jsx" */),
  "component---src-pages-auth-jsx": () => import("./../../../src/pages/auth.jsx" /* webpackChunkName: "component---src-pages-auth-jsx" */),
  "component---src-pages-blog-blog-dark-jsx": () => import("./../../../src/pages/blog/blog-dark.jsx" /* webpackChunkName: "component---src-pages-blog-blog-dark-jsx" */),
  "component---src-pages-blog-blog-light-jsx": () => import("./../../../src/pages/blog/blog-light.jsx" /* webpackChunkName: "component---src-pages-blog-blog-light-jsx" */),
  "component---src-pages-blog-details-blog-details-dark-jsx": () => import("./../../../src/pages/blog-details/blog-details-dark.jsx" /* webpackChunkName: "component---src-pages-blog-details-blog-details-dark-jsx" */),
  "component---src-pages-blog-details-blog-details-light-jsx": () => import("./../../../src/pages/blog-details/blog-details-light.jsx" /* webpackChunkName: "component---src-pages-blog-details-blog-details-light-jsx" */),
  "component---src-pages-blog-grid-blog-grid-dark-jsx": () => import("./../../../src/pages/blog-grid/blog-grid-dark.jsx" /* webpackChunkName: "component---src-pages-blog-grid-blog-grid-dark-jsx" */),
  "component---src-pages-blog-grid-blog-grid-light-jsx": () => import("./../../../src/pages/blog-grid/blog-grid-light.jsx" /* webpackChunkName: "component---src-pages-blog-grid-blog-grid-light-jsx" */),
  "component---src-pages-blog-list-blog-list-dark-jsx": () => import("./../../../src/pages/blog-list/blog-list-dark.jsx" /* webpackChunkName: "component---src-pages-blog-list-blog-list-dark-jsx" */),
  "component---src-pages-blog-list-blog-list-light-jsx": () => import("./../../../src/pages/blog-list/blog-list-light.jsx" /* webpackChunkName: "component---src-pages-blog-list-blog-list-light-jsx" */),
  "component---src-pages-contact-contact-dark-jsx": () => import("./../../../src/pages/contact/contact-dark.jsx" /* webpackChunkName: "component---src-pages-contact-contact-dark-jsx" */),
  "component---src-pages-contact-contact-light-jsx": () => import("./../../../src/pages/contact/contact-light.jsx" /* webpackChunkName: "component---src-pages-contact-contact-light-jsx" */),
  "component---src-pages-homepage-home-5-dark-jsx": () => import("./../../../src/pages/homepage/home5-dark.jsx" /* webpackChunkName: "component---src-pages-homepage-home-5-dark-jsx" */),
  "component---src-pages-how-it-works-jsx": () => import("./../../../src/pages/how-it-works.jsx" /* webpackChunkName: "component---src-pages-how-it-works-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-platform-document-jsx": () => import("./../../../src/pages/platform/document.jsx" /* webpackChunkName: "component---src-pages-platform-document-jsx" */),
  "component---src-pages-platform-jsx": () => import("./../../../src/pages/platform.jsx" /* webpackChunkName: "component---src-pages-platform-jsx" */),
  "component---src-pages-privacy-jsx": () => import("./../../../src/pages/privacy.jsx" /* webpackChunkName: "component---src-pages-privacy-jsx" */),
  "component---src-pages-showcase-3-showcase-3-dark-jsx": () => import("./../../../src/pages/showcase3/showcase3-dark.jsx" /* webpackChunkName: "component---src-pages-showcase-3-showcase-3-dark-jsx" */),
  "component---src-pages-showcase-3-showcase-3-light-jsx": () => import("./../../../src/pages/showcase3/showcase3-light.jsx" /* webpackChunkName: "component---src-pages-showcase-3-showcase-3-light-jsx" */),
  "component---src-pages-showcase-4-showcase-4-dark-jsx": () => import("./../../../src/pages/showcase4/showcase4-dark.jsx" /* webpackChunkName: "component---src-pages-showcase-4-showcase-4-dark-jsx" */),
  "component---src-pages-showcase-4-showcase-4-light-jsx": () => import("./../../../src/pages/showcase4/showcase4-light.jsx" /* webpackChunkName: "component---src-pages-showcase-4-showcase-4-light-jsx" */),
  "component---src-pages-showcase-showcase-dark-jsx": () => import("./../../../src/pages/showcase/showcase-dark.jsx" /* webpackChunkName: "component---src-pages-showcase-showcase-dark-jsx" */),
  "component---src-pages-showcase-showcase-light-jsx": () => import("./../../../src/pages/showcase/showcase-light.jsx" /* webpackChunkName: "component---src-pages-showcase-showcase-light-jsx" */),
  "component---src-pages-terms-jsx": () => import("./../../../src/pages/terms.jsx" /* webpackChunkName: "component---src-pages-terms-jsx" */),
  "component---src-pages-works-2-works-2-dark-jsx": () => import("./../../../src/pages/works2/works2-dark.jsx" /* webpackChunkName: "component---src-pages-works-2-works-2-dark-jsx" */),
  "component---src-pages-works-2-works-2-light-jsx": () => import("./../../../src/pages/works2/works2-light.jsx" /* webpackChunkName: "component---src-pages-works-2-works-2-light-jsx" */),
  "component---src-pages-works-3-works-3-dark-jsx": () => import("./../../../src/pages/works3/works3-dark.jsx" /* webpackChunkName: "component---src-pages-works-3-works-3-dark-jsx" */),
  "component---src-pages-works-3-works-3-light-jsx": () => import("./../../../src/pages/works3/works3-light.jsx" /* webpackChunkName: "component---src-pages-works-3-works-3-light-jsx" */),
  "component---src-pages-works-works-dark-jsx": () => import("./../../../src/pages/works/works-dark.jsx" /* webpackChunkName: "component---src-pages-works-works-dark-jsx" */),
  "component---src-pages-works-works-light-jsx": () => import("./../../../src/pages/works/works-light.jsx" /* webpackChunkName: "component---src-pages-works-works-light-jsx" */)
}

