import React, { createContext, useContext, useState, useEffect } from "react";

const AuthContext = createContext(null);

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [authTimestamp, setAuthTimestamp] = useState(false);
  const [authErrorMessage, setAuthErrorMessage] = useState(false);

  const setTokensToLocalStorage = (response) => {
    // Extract tokens from headers
    const headers = response.headers;

    const tokens = {};
    headers.forEach((value, key) => {
      if (key.toLowerCase().includes("_token")) {
        tokens[key.toLowerCase()] = value;
      }
    });

    if (Object.keys(tokens).length === 0) {
      throw new Error("No tokens found in response headers");
    }

    // Save tokens to local storage
    for (const [key, value] of Object.entries(tokens)) {
      localStorage.setItem(key + "9e", value);
    }
  };

  const removeTokensFromLocalStorage = () => {
    // Remove tokens from local storage
    const keys = Object.keys(localStorage);
    keys.forEach((key) => {
      if (key.includes("_token9e")) {
        localStorage.removeItem(key);
      }
    });
  };

  const getTokensFromLocalStorage = () => {
    // Get tokens from local storage
    const tokens = {};
    const keys = Object.keys(localStorage);
    keys.forEach((key) => {
      if (key.includes("_token9e")) {
        tokens[key] = localStorage.getItem(key);
      }
    });

    return tokens;
  };

  const checkAuthentication = async () => {
    // check if the user is authenticated
    if (isAuthenticated) {
      // check if the authentication token is still valid
      const currentTime = Date.now();
      const timeElapsed = currentTime - authTimestamp;
      if (timeElapsed > 60 * 30 * 1000) {
        // 60 secs * 30 mins * 1000 ms
        // refresh the authentication token if older than 30 mins
        console.log("Refreshing authentication token due to timeout");
        await refreshAuthentication();
      }
    } else {
      // check if the user is authenticated
      await refreshAuthentication();
    }
  };
  const refreshAuthentication = async () => {
    setIsLoading(true);

    try {
      const tokens = getTokensFromLocalStorage();

      if (!("refresh_token9e" in tokens)) {
        setAuthTimestamp(null);
        setIsAuthenticated(false);
        setIsLoading(false);

        return;
      }

      const apiUrl = `${process.env.GATSBY_BACKEND_API_BASE}/auth/refresh`;
      const response = await fetch(apiUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "X-API-KEY": process.env.GATSBY_BACKEND_API_KEY,
          access_token: tokens["access_token9e"],
          refresh_token: tokens["refresh_token9e"],
        },
        body: "{}",
      });

      const userData = await response.json();
      if ("user_data" in userData && response.ok) {
        setAuthTimestamp(Date.now());
        setIsAuthenticated(true);
        setUser(userData);
        setTokensToLocalStorage(response);
      } else {
        setAuthTimestamp(null);
        setIsAuthenticated(false);
      }
    } catch (error) {
      console.error("Error refreshing authentication status:", error);
      setUser(null);
      setIsAuthenticated(false);
      setAuthTimestamp(null);
    }
    setIsLoading(false);
  };

  const validateEmail = (value) => {
    let error;
    if (!value) {
      error = "Email is required";
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(value)) {
      error = "Invalid email address";
    }
    return error;
  };

  const validateActivationCode = (value) => {
    let error;
    if (!value) {
      error = "Activation code is required";
    }
    return error;
  };

  // Function to handle Sign Up
  const handleSignUp = async (formData) => {
    const apiUrl = `${process.env.GATSBY_BACKEND_API_BASE}/auth/signup`;
    const payload = {
      username: formData.email, // Assuming the email input's name is 'email'
      password: formData.password,
    };
    try {
      const response = await fetch(apiUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "X-API-KEY": process.env.GATSBY_BACKEND_API_KEY,
        },
        body: JSON.stringify(payload),
        credentials: "include",
      });

      const data = await response.json();
      console.log("Sign Up Success:", data);
      return true;
    } catch (error) {
      console.error("Sign Up Error:", error);
      setAuthErrorMessage("Sign up failed. Please try again.");
      return false;
    }
  };

  // Function to handle Activation
  const handleActivation = async (formData) => {
    const apiUrl = `${process.env.GATSBY_BACKEND_API_BASE}/auth/confirm_signup`;
    const payload = {
      username: formData.email,
      confirmation_code: formData.confirmation_code,
    };

    try {
      const response = await fetch(apiUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "X-API-KEY": process.env.GATSBY_BACKEND_API_KEY,
        },
        body: JSON.stringify(payload),
        credentials: "include",
      });

      if (!response.ok) {
        console.log("Network response was not ok during sign up.");
        return false;
      }

      const data = await response.json();
      console.log("Sign Up Success:", data);
      return true;
    } catch (error) {
      console.error("Sign Up Error:", error);
      return false;
    }
  };

  // Function to handle Sign In
  const handleSignIn = async (formData) => {
    const apiUrl = `${process.env.GATSBY_BACKEND_API_BASE}/auth/login`;
    const payload = {
      username: formData.email, // Assuming the email input's name is 'email'
      password: formData.password,
    };

    try {
      const response = await fetch(apiUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "X-API-KEY": process.env.GATSBY_BACKEND_API_KEY,
        },
        body: JSON.stringify(payload),
      });

      if (!response.ok) {
        throw new Error("Network response was not ok during sign in.");
      }

      const data = await response.json();
      if ("user_data" in data) {
        console.log("Sign In Success:");
        setTokensToLocalStorage(response);
        setUser(data);
        setAuthTimestamp(Date.now());
        setIsAuthenticated(true);

        return true;
      } else {
        console.log("Sign In Error:", data);
        setAuthTimestamp(null);
        setIsAuthenticated(false);
        return false;
      }
      // Handle sign in success (e.g., store token, redirect)
    } catch (error) {
      console.log("Sign In Error:", error);
      setAuthErrorMessage("Wrong email or password. Please try again.");
      setAuthTimestamp(null);
      setIsAuthenticated(false);
    }
  };

  // Function to handle Sign Out
  const handleSignOut = async (formData) => {
    try {
      setUser(null);
      setAuthTimestamp(null);
      setIsAuthenticated(false);
      removeTokensFromLocalStorage();
      console.log("Logout Success:");

      // Handle logout success (e.g., store token, redirect)
    } catch (error) {
      console.error("Log out Error:", error);
    }
  };

  useEffect(() => {
    if (typeof window !== "undefined") {
      checkAuthentication();
    }
  }, []);

  return (
    <AuthContext.Provider
      value={{
        user,
        setUser,
        isLoading,
        isAuthenticated,
        authErrorMessage,
        setAuthErrorMessage,
        checkAuthentication,
        handleSignUp,
        handleSignIn,
        handleSignOut,
        handleActivation,
        validateEmail,
        validateActivationCode,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

// Hook to use authentication context
export const useAuth = () => useContext(AuthContext);
