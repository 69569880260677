import React from "react";
import Aq from "./aq";
import "./faq.css";

const Faq = () => {
  return (
    <section id="faq" className="app-faq section-padding wow fadeInUp">
      <div className="container">
        <div className="faq-row">
          <div className="faq-content">
            <div className="content md-mb50">
              <h6 className="stit mb-30">Faqs</h6>
              <h2 className="mb-30">
                What is 9epoch Platform?
              </h2>
              <p>
                9epoch platform is a suite of AI tools proven to help traders, hedge funds and investors leverage their existing data sources and make more informed, timely decisions
              </p>
              <a href="/auth?signup=true" className="butn-bord-red rounded buton mt-30">
                <span>Get 5 Documents For Free</span>
              </a>
            </div>
          </div>

          <div className="faq-content">
            <Aq
              question={"What can I do with 9epoch platform ?"}
              answer={`Upload or forward any research or company documents either in pdf, zip or text format and our AI will analyse and summarise from an investment perspective.`}
              open={true}
            />

            <Aq
              question={"What are examples of impactful data from our AI?"}
              answer={`We have trained the AI to look for the most price impactful data within a document, and this can vary but usually includes research recommendation changes, target price changes, earnings surprises, and M&A announcements.`}
            />
            <Aq
              question={"How can I use the platform to save time?"}
              answer={`Simply autoforward your investment research to your 9epoch private inbox and we will do the rest. Connect your ms teams or slack channel to get realtime alerts for only the most important changes, saving you from reading all of the research reports.`}
            />
            <Aq
              question={"What quantitative analysis can I do with 9epoch platform?"}
              answer={`For every document with a stock ticker, we do an event study around the publish time of the document to automatically generate a set of over 100 quantiative metrics such as returns, volatility, volume, and many others. You can view these or export them to excel for further analysis.`}
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default Faq;
