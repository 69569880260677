import React, { useCallback, useEffect, useState } from "react";
import particlesConfig from "config/particle-config";
import particlesBlackConfig from "config/pr-s-black";
import Particles from "react-tsparticles";
import { loadFull } from "tsparticles";
import "./DocumentDetailView.css";
import { getDocument } from "../Api";
import InnerLoader from "components/Auth/inner-loader";
import { useLocation } from "@reach/router"; // Import useLocation
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Button from "@mui/material/Button";
import UploadFileIcon from "@mui/icons-material/UploadFile";

import { navigate } from "gatsby";

function DocumentDetail({ blackStar }) {
  const [loading, setLoading] = useState(true);
  const [documentData, setDocumentData] = useState({}); // Add this line
  const location = useLocation();

  const particlesInit = useCallback(async (engine) => {
    await loadFull(engine);
    document
      .querySelector("#particles-js canvas")
      ?.style.removeProperty("position");
  }, []);

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const id = searchParams.get("id") || "";

    setLoading(true);
    getDocument(id).then((data) => {
      setDocumentData(data);
      setLoading(false);
    });
  }, []);

  function KeyValuePairs({ data }) {
    return (
      <div>
        {Object.entries(data).map(([key, value]) => (
          <div className="document-view-key-value " key={key}>
            <span className="document-view-info-pair-label">{key}</span>
            <span className="document-view-info-pair-value">{value}</span>
          </div>
        ))}
      </div>
    );
  }

  return (
    <header className=" circle-bg valign documents-view">
      <div className="container documents-view-wrapper">
        <InnerLoader loading={loading} />

        {!loading && documentData && (
          <>
            <h1 class="wow color-font animated document-view-title">
              {documentData?.title}
            </h1>
            <div className="document-view-button-holder">
              <Button
                variant="outlined"
                sx={{
                  color: "white",
                  borderColor: "rgb(131, 131, 131)",
                  "&:hover": {
                    backgroundColor: "rgba(255, 255, 255, 0.08)",
                    borderColor: "white",
                  },
                }}
                startIcon={<ArrowBackIcon />}
                onClick={() => {
                  navigate("/platform");
                }}
              >
                Document List
              </Button>
              <Button
                variant="outlined"
                sx={{
                  color: "white",
                  borderColor: "rgb(131, 131, 131)",
                  "&:hover": {
                    backgroundColor: "rgba(255, 255, 255, 0.08)",
                    borderColor: "white",
                  },
                }}
                startIcon={<UploadFileIcon />}
                onClick={() => {
                  if (!documentData?.signed_url) return;
                  window.open(documentData?.signed_url);
                }}
              >
                Download
              </Button>
            </div>

            <div className="document-view-broad-info-row">
              <div className="document-view-info-box">
                <div className="document-view-info-text">Published at</div>
                <div> {documentData?.published_at} </div>
              </div>
              <div className="document-view-info-box">
                <div className="document-view-info-text">Note Type</div>
                <div> {documentData?.note_type} </div>
              </div>

              <div className="document-view-info-box">
                <div className="document-view-info-text">Broker</div>
                <div> {documentData?.broker} </div>
              </div>
              <div className="document-view-info-box">
                <div className="document-view-info-text">Primary Ticker</div>
                <div> {documentData?.primary_ticker} </div>
              </div>
              <div className="document-view-info-box">
                <div className="document-view-info-text">Primary Exchange</div>
                <div> {documentData?.primary_exchange} </div>
              </div>
              <div className="document-view-info-box">
                <div className="document-view-info-text">Analyst</div>
                <div> {documentData?.analyst} </div>
              </div>
            </div>

            <div className="document-view-info-container">
              <div className="document-view-info-row">
                <span className="document-view-info-label">TAGS:</span>
                {documentData?.nlp_features?.openai_analysis?.TAGS}
              </div>
            </div>
            <div className="document-view-info-container">
              <div className="document-view-info-column">
                <span className="document-view-info-label">STORY</span>
                {documentData?.nlp_features?.openai_analysis?.STORY}
              </div>
            </div>
            <div className="document-view-info-row-of-containers">
              <div className="document-view-info-container-half">
                <div className="document-view-info-column">
                  <span className="document-view-info-title-label">
                    Language Features
                  </span>
                  <div className="document-view-key-value-column">
                    {documentData?.nlp_features?.openai_analysis && (
                      <KeyValuePairs
                        data={documentData?.nlp_features?.openai_analysis}
                      />
                    )}
                  </div>
                </div>
              </div>
              <div className="document-view-info-container-half">
                <div className="document-view-info-column">
                  <span className="document-view-info-title-label">
                    Quant Features
                  </span>
                  <div className="document-view-key-value-column">
                    {documentData?.market_features && (
                      <KeyValuePairs data={documentData?.market_features} />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
      <Particles
        id="particles-js"
        init={particlesInit}
        options={blackStar ? particlesBlackConfig : particlesConfig}
      />
      <div className="gradient-circle"></div>
      <div className="gradient-circle two"></div>
    </header>
  );
}

export default DocumentDetail;
