import React, { useState, useRef, useEffect } from "react";
import { Formik, Form, Field } from "formik";
import { navigate, useLocation } from "@reach/router"; // Import useLocation
import DarkTheme from "layouts/Dark";
import "./AuthComponent.css";
import InnerLoader from "./inner-loader";
import { useAuth } from "../../../authContext";

const ConfirmAuthComponent = () => {
  const { validateActivationCode, validateEmail, handleActivation } = useAuth();

  const messageRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const [mounted, setMounted] = useState(false);
  const [initialValues, setInitialValues] = useState({
    email: "",
    confirmation_code: "",
  });
  const location = useLocation();

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const emailParam = searchParams.get("email") || "";
    const confirmationCodeParam = searchParams.get("confirmation_code") || "";

    setInitialValues({
      email: emailParam,
      confirmation_code: confirmationCodeParam,
    });
    setMounted(true);
  }, [location]);

  return (
    <DarkTheme>
      {mounted && (
        <section className="contact section-padding auth-view">
          <div className="container">
            <div className="auth-view-holder">
              <div className="auth-view-wrapper">
                <InnerLoader loading={loading} />
                <div className="form md-mb50">
                  <h4 className="fw-700 color-font mb-50">Activation</h4>
                  <Formik
                    initialValues={initialValues}
                    onSubmit={async (values, { setSubmitting, resetForm }) => {
                      setLoading(true);
                      await handleActivation(values);

                      navigate("/auth");

                      setLoading(false);
                      setSubmitting(false);
                      resetForm();
                    }}
                  >
                    {({ errors, touched }) => (
                      <Form id="auth-form">
                        <div className="messages" ref={messageRef}></div>
                        <div className="controls">
                          <div className="form-group">
                            <Field
                              validate={validateEmail}
                              id="form_email"
                              type="email"
                              name="email"
                              placeholder="Email"
                              values="asdfsa"
                            />
                            {touched.email && <div>{errors.email}</div>}
                          </div>
                          <div className="form-group">
                            <Field
                              validate={validateActivationCode}
                              id="form_activation"
                              type="text"
                              name="confirmation_code"
                              placeholder="Activation code"
                            />
                            {touched.confirmation_code && (
                              <div>{errors.confirmation_code}</div>
                            )}
                          </div>
                        </div>

                        <div className="auth-buttons">
                          <button
                            type="submit"
                            className="butn bord curve wow fadeInUp"
                          >
                            <span>Activate</span>
                          </button>
                        </div>
                      </Form>
                    )}
                  </Formik>
                </div>
              </div>
            </div>
          </div>
        </section>
      )}
    </DarkTheme>
  );
};

export default ConfirmAuthComponent;
