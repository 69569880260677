import React, { useEffect } from "react";
import "./header.css";

const PagesHeader = () => {
  return (
    <header className="pages-header circle-bg valign">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-10">
            <div className="cont mt-100 mb-50 text-center">
              <h1 className="color-font fw-700">
                Quantitative software refined by experience
              </h1>
            </div>
          </div>
        </div>
        <div
          className="supademo-wrapper-3"
          style={{
            position: "relative",
            height: 0,
            width: "100%",
          }}
        >
          <iframe
            src="https://app.supademo.com/embed/clxvhuv480np812o29k6xxw4o"
            className="supademo-3"
            title="Main Platform Demo"
            allow="clipboard-write"
            frameborder="0"
            webkitallowfullscreen="true"
            mozallowfullscreen="true"
            allowFullScreen={true}
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              border: "none",
            }}
          ></iframe>
        </div>
      </div>
      <div className="sub-bg">
        <div className="circle-color">
          <div className="gradient-circle"></div>
          <div className="gradient-circle two"></div>
        </div>
      </div>
    </header>
  );
};

export default PagesHeader;
