import React, { useEffect, useRef } from "react";
import Navbar from "components/Navbar/navbar";
import DarkTheme from "layouts/Dark";
import { useAuth } from "../../authContext";
import { navigate } from "gatsby";
import DocumentsView from "components/Platform/DocumentsView";

const PlatformHome = () => {
  const navbarRef = useRef(null);
  const logoRef = useRef(null);
  const { user, isLoading, isAuthenticated } = useAuth();

  useEffect(() => {
    const navbar = navbarRef.current;
    const handleScroll = () => {
      if (window.pageYOffset > 300) {
        navbar.classList.add("nav-scroll");
      } else {
        navbar.classList.remove("nav-scroll");
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [isAuthenticated, user, navigate]);

  if (isLoading || !isAuthenticated) {
    return <div>Loading...</div>;
  }

  return (
    <DarkTheme>
      <Navbar nr={navbarRef} lr={logoRef} />
      <DocumentsView />
      {/* Additional content can be added here */}
    </DarkTheme>
  );
};

export default PlatformHome;
