import React from "react";
import "./pricing.css";
import PricingCard from "./PricingCard";

function Pricing() {
  return (
    <section id="pricing" className="app-pricing section-padding">
      <div className="container">
        <div className="pricing-title-box">
          <div className="pricing-title">Pricing </div>
          <div className="pricing-subtitle">
            Find the right plan to streamline your trading research
          </div>
        </div>
        <div className="pricing-row">
          <PricingCard
            planName="Free Plan"
            subtitle="Try out quant magic"
            price="0$"
            period="/mo"
            button={"Get Started for free"}
            toLink="/auth?signup=true"
            features={[
              "Market impact filters",
              "1 page summaries",
              "Private and protected",
              "5 documents per month",
            ]}
          />

          <PricingCard
            bestDeal={true}
            planName="Pay As You Go"
            subtitle="90c per document"
            price="90$"
            period="/mo"
            toLink="/auth?signup=true"
            button={"Start Now"}
            features={[
              "Market impact filters",
              "1 page summaries",
              "Private and protected",
              "90 documents per month",
              "Additional documents 90c each",
              "Live Support",
            ]}
          />

          <PricingCard
            planName="Enterprise"
            subtitle="Tailored to your needs"
            price=""
            period=""
            toLink="/contact/contact-dark/"
            button={"Contact Us"}
            features={[
              "Market impact filters",
              "1 page summaries",
              "Private and protected",
              "90 documents per month",
              "Additional documents 90c each",
              "Priority support",
              "Customisation",
            ]}
          />
        </div>
      </div>
      <div className="line bottom left"></div>
    </section>
  );
}

export default Pricing;
