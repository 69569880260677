import React, { useState, useRef, useEffect } from "react";
import { DateRange } from "react-date-range";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import { enUS } from "date-fns/locale";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import Fade from "@mui/material/Fade";
import Popper from "@mui/material/Popper";
import Paper from "@mui/material/Paper";
import InputBase from "@mui/material/InputBase";
import IconButton from "@mui/material/IconButton";
import FormControl from "@mui/material/FormControl";

import DateRangeIcon from "@mui/icons-material/DateRange";

function DatePicker({ state, setState }) {
  const [open, setOpen] = useState(false);
  const [displayedRange, setDisplayedRange] = useState("");
  const anchorRef = useRef(null);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  useEffect(() => {
    if (state[0]?.startDate && state[0]?.endDate) {
      const formattedStartDate = state[0].startDate.toLocaleDateString(
        "en-US",
        { year: "numeric", month: "2-digit", day: "2-digit" }
      );
      const formattedEndDate = state[0].endDate.toLocaleDateString("en-US", {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
      });

      setDisplayedRange(`${formattedStartDate} - ${formattedEndDate}`);
      return;
    }
    setDisplayedRange("");
  }, [state]);

  const handleNullDates = (state) => {
    if (state[0]?.startDate === null || state[0]?.endDate === null) {
      return [
        {
          startDate: new Date(),
          endDate: new Date(),
          key: "selection",
          color: "rgb(75, 143, 217)",
        },
      ];
    }
    return state;
  };
  return (
    <div>
      <FormControl className="date-picker">
        <div class="documents-view-input-label" htmlFor="search-input">
          Search by Date Range
        </div>
        <Paper ref={anchorRef} class="documents-view-input">
          <InputBase placeholder="Pick a date range" value={displayedRange} />
          <IconButton id="search-input" type="button" aria-label="search">
            <DateRangeIcon onClick={handleToggle} />
          </IconButton>
        </Paper>

        <Popper
          open={open}
          anchorEl={anchorRef.current}
          role={undefined}
          transition
          disablePortal
        >
          {({ TransitionProps }) => (
            <Fade {...TransitionProps} timeout={350}>
              <div>
                <ClickAwayListener onClickAway={handleClose}>
                  <div>
                    <DateRange
                      editableDateInputs={true}
                      onChange={(item) => setState([item.selection])}
                      moveRangeOnFirstSelection={false}
                      ranges={handleNullDates(state)}
                      locale={enUS}
                    />
                  </div>
                </ClickAwayListener>
              </div>
            </Fade>
          )}
        </Popper>
      </FormControl>
    </div>
  );
}

export default DatePicker;
