import React, { useRef, useState, useEffect } from "react";
import "./aq.css";

function Aq({ question, answer, open = false }) {
  const [active, setActive] = useState(open);
  const accordionInfo = useRef(null);
  const paragraphRef = useRef(null);

  useEffect(() => {
    if (active && accordionInfo.current && paragraphRef.current) {
      accordionInfo.current.style.height = `${
        paragraphRef.current.scrollHeight + 8
      }px`;
    } else if (accordionInfo.current) {
      accordionInfo.current.style.height = "0px";
    }
  }, [active]);

  return (
    <div className="accordion ">
      <div className="title">
        <h6 className="accordion-question" onClick={() => setActive((a) => !a)}>
          <span className=""> {question} </span>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
            className={`size-6 accordion-icon ${
              active && "accordion-icon-rotated"
            }`}
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M15.75 19.5 8.25 12l7.5-7.5"
            />
          </svg>
        </h6>
      </div>
      <div
        ref={accordionInfo}
        className={`accordion-info ${active && "accordion-info-open"}`}
      >
        <p ref={paragraphRef}>{answer}</p>
      </div>
    </div>
  );
}

export default Aq;
