import React from "react";
import { Link } from "gatsby";
import cardMouseEffect from "common/cardMouseEffect";
import "./services.css";

const Services3 = () => {
  React.useEffect(() => {
    cardMouseEffect(document.querySelectorAll(".feat .items"));
  }, []);
  return (
    <>
      <section
        id="features"
        className="feat services-container   section-padding"
      >
        <div className="container">
          <div className="row ">
            <div className="col-lg-8 col-md-10">
              <div className="services-head">
                <h6 className="color-font" data-wow-delay=".5s">
                  Quant Features
                </h6>
                <h3 className="wow">
                  Real-time actionable signals for traders & PM's
                </h3>
              </div>
            </div>
          </div>

          <div className="wow fadeInUp gradient-border-box">
            <div className="col-lg-3 col-md-6  md-mb30 service-border-box">
              <div className="item service-box" data-wow-delay=".3s">
                <span className="icon">
                  <i className="ion-ios-monitor"></i>
                </span>
                <h5>Harness More Data</h5>
                <p>
                  Read, understand and extract value from more data sources such
                  as filings, transcripts & broker research
                </p>
                <Link to="/about/about-dark" className="more-stroke">
                  <span></span>
                </Link>
              </div>
            </div>
            <div className="col-lg-3 col-md-6  md-mb30 service-border-box">
              <div className="item service-box" data-wow-delay=".3s">
                <span className="icon">
                  <i className="ion-ios-bolt-outline"></i>
                </span>
                <h5>Extract Alpha</h5>
                <p>
                  Extracts over 30 deep and significant quant features from
                  documents which can be used to predict price movements
                </p>
                <Link to="/about/about-dark" className="more-stroke">
                  <span></span>
                </Link>
              </div>
            </div>

            <div className="col-lg-3 col-md-6  md-mb30 service-border-box">
              <div className="item service-box" data-wow-delay=".3s">
                <span className="icon">
                  <i className="ion-cube"></i>
                </span>
                <h5>Automate Coverage</h5>
                <p>
                  Save time & improve productivity by automating tasks such as
                  news reading, data analysis & report writing
                </p>
                <Link to="/about/about-dark" className="more-stroke">
                  <span></span>
                </Link>
              </div>
            </div>

            <div className="col-lg-3 col-md-6  md-mb30 service-border-box">
              <div className="item service-box" data-wow-delay=".3s">
                <span className="icon">
                  <i className="ion-ios-color-wand"></i>
                </span>
                <h5>Real-time Alerts</h5>
                <p>
                  Remove the noise and distill the most urgent information. Get
                  custom instant messages via teams, email, etc direct to
                  traders & PM's
                </p>
                <Link to="/about/about-dark" className="more-stroke">
                  <span></span>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Services3;
