import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import TablePagination from "@mui/material/TablePagination";
import SearchIcon from "@mui/icons-material/Search";
import IconButton from "@mui/material/IconButton";
import InputBase from "@mui/material/InputBase";
import Tooltip from "@mui/material/Tooltip";
import { getDocuments } from "./Api";
import Filters from "./Filters";
import Backdrop from "@mui/material/Backdrop";
import Grow from "@mui/material/Grow";
import Popper from "@mui/material/Popper";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import CircularProgress from "@mui/material/CircularProgress";
import { navigate } from "gatsby";
import { useState, useEffect, useImperativeHandle, forwardRef } from "react";
import EpochTooltip from "./EpochTooltip";
import { useAuth } from "../../../authContext";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import { formatDateForCell, formatDateForTooltip } from "helpers";

const EpochTable = forwardRef((_, ref) => {
  const { user } = useAuth();
  const [openMessage, setOpenMessage] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [search, setSearch] = useState("");
  const [rows, setRows] = useState([]);
  const [limit, setLimit] = useState(25);
  const [clear, setClearFilters] = useState(false);
  const [filters, setFilters] = useState({
    dateRange: [
      {
        startDate: null,
        endDate: null,
        key: "selection",
        color: "rgb(75, 143, 217)",
      },
    ],
    ticker: null,
  });

  useImperativeHandle(ref, () => ({
    search,
    rowsPerPage,
    page,
    filters,
  }));

  const [loading, setLoading] = useState(false);

  const anchorRef = React.useRef([]);

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      getTableFilterData();
    }
  };

  const handleChangePage = (_, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(event.target.value);
    setPage(0);
  };

  const getTableData = async () => {
    const payload = {
      search_text: search,
      limit: rowsPerPage,
      offset: rowsPerPage * page,
      ticker: filters.ticker,
      export_csv: false,
    };

    if (filters?.dateRange[0]?.startDate && filters?.dateRange[0]?.endDate) {
      payload.start_datetime = formatDateForCell(
        filters?.dateRange[0]?.startDate
      );
      payload.end_datetime = formatDateForCell(filters?.dateRange[0]?.endDate);
    }

    setLoading(true);
    await getDocuments(payload)
      .then((data) => {
        setRows(data?.documents);
        setLimit(data?.total ?? 0);
      })
      .catch(() => {
        setLoading(false);
      });
    setLoading(false);
  };

  const getTableFilterData = async () => {
    setPage(0);
    await getTableData();
  };

  const clearFilters = async () => {
    setPage(0);
    setFilters({
      dateRange: [
        {
          startDate: null,
          endDate: null,
          key: "selection",
          color: "rgb(75, 143, 217)",
        },
      ],
      ticker: "",
    });
    setClearFilters(true);
  };

  useEffect(() => {
    getTableData();
  }, [page, rowsPerPage]);

  useEffect(() => {
    if (clear) {
      getTableData();
      setClearFilters(false);
    }
  }, [clear]);

  const pickDocument = (document) => () => {
    navigate(`/platform/document?id=${document.id}`);
  };

  function openLink(input) {
    if (isValidEmail(input)) {
      var mailtoLink = "mailto:" + input;
      window.location.href = mailtoLink;
    } else {
      var url = input;
      window.open(url, "_blank");
    }
  }

  function isValidEmail(email) {
    var re = /\S+@\S+\.\S+/;
    return re.test(email);
  }

  return (
    <>
      <div class="documents-view-tool-row">
        <div className="documents-view-tool-column-info">
          <div>
            <Tooltip
              title={`Simply forward/send pdf documents & reports to this email to
                    analyse them with 9epoch Platform AI`}
              placement="top"
              arrow
              className="documents-view-document-inbox"
            >
              <HelpOutlineIcon fontSize="small" /> Your private document inbox
              is:
            </Tooltip>

            <div>{user?.user_data?.document_inbox}</div>
          </div>
        </div>
        <div className="documents-view-tool-row-filters">
          <Paper class="documents-view-input">
            <InputBase
              placeholder="Search..."
              value={search}
              onChange={(v) => setSearch(v.target.value)}
              onKeyUp={handleKeyPress}
            />
            <IconButton
              id="search-input"
              type="button"
              aria-label="search"
              onClick={() => getTableFilterData()}
            >
              <SearchIcon />
            </IconButton>
          </Paper>
          <Filters
            filters={filters}
            setFilters={setFilters}
            getTableFilterData={getTableFilterData}
            clearFilters={clearFilters}
          />
        </div>
      </div>
      <TableContainer component={Paper} sx={{ position: "relative" }}>
        <Backdrop
          sx={{
            color: "#fff",
            zIndex: 1,
            position: "absolute",
            top: "0",
            left: "0",
          }}
          open={loading}
        >
          <CircularProgress size={48} />
        </Backdrop>

        <Table
          sx={{ minWidth: 650 }}
          aria-label="simple table"
          loading={loading}
        >
          <TableHead>
            <TableRow>
              <TableCell>Date</TableCell>
              <TableCell>Title</TableCell>
              <TableCell>Ticker</TableCell>
              <TableCell>Sentiment</TableCell>
              <TableCell>
                <EpochTooltip
                  tooltip={
                    "Estimated market impact of the report from LOW to HIGH. Based on sentiment, rec changes & other factors."
                  }
                  label={"Impact"}
                />
              </TableCell>
              <TableCell>
                <EpochTooltip
                  tooltip={
                    "The price return of the target security from the release date to the next close."
                  }
                  label={"Return"}
                />
              </TableCell>
              <TableCell>
                <EpochTooltip
                  tooltip={
                    "Relative Volume ratio. >1 means volume was higher than average for the target security on the day."
                  }
                  label={"RVOL"}
                />
              </TableCell>
              <TableCell>Messages</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows?.map((row, index) => (
              <TableRow key={row.id}>
                <TableCell component="th" scope="row">
                  <Tooltip
                    title={formatDateForTooltip(row.published_at)}
                    placement="top"
                    arrow
                    className="documents-view-date"
                  >
                    {formatDateForCell(row.published_at)}
                  </Tooltip>
                </TableCell>
                <TableCell>
                  <span
                    className="documents-view-title-cell"
                    onClick={pickDocument(row)}
                  >
                    {row.title ? row.title : "-"}
                  </span>
                  <div className="documents-view-broker">
                    {row?.broker ? row.broker : "-"}
                  </div>
                </TableCell>
                <TableCell>
                  {row?.primary_ticker ? row?.primary_ticker : "-"}
                </TableCell>
                <TableCell
                  className={
                    row.adjusted_avg_sentiment * 100 < 5
                      ? "documents-view-negative"
                      : row.adjusted_avg_sentiment * 100 > 10
                      ? "documents-view-positive"
                      : ""
                  }
                >
                  {row?.adjusted_avg_sentiment
                    ? `${parseFloat(row.adjusted_avg_sentiment * 100).toFixed(
                        2
                      )}%`
                    : "-"}
                </TableCell>{" "}
                <TableCell>{row?.impact ? row?.impact : "-"}</TableCell>
                <TableCell>
                  {row?.close_to_close_return
                    ? `${parseFloat(row.close_to_close_return * 100).toFixed(
                        2
                      )}%`
                    : "-"}
                </TableCell>
                <TableCell>
                  {row?.intraday_rvol
                    ? `${parseFloat(row.intraday_rvol).toFixed(2)}x`
                    : "-"}
                </TableCell>
                <TableCell
                  onClick={() => {
                    let array = openMessage;
                    array[index] = !array[index];
                    setOpenMessage([...array]);
                  }}
                  ref={(i) => anchorRef.current.push(i)}
                  className="documents-view-messages-cell"
                >
                  {row?.messages_list?.length > 0 ? "Sent" : "-"}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={limit}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </TableContainer>

      {rows?.map((row, index) => (
        <Popper
          open={openMessage[index]}
          anchorEl={anchorRef.current[index]}
          role={undefined}
          placement="bottom"
          transition
          disablePortal
          className="documents-view-popper"
        >
          {({ TransitionProps }) => (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin: "top",
              }}
            >
              <Paper class="documents-view-popper-message-module">
                <ClickAwayListener
                  onClickAway={() => {
                    let array = openMessage;
                    array[index] = false;
                    setOpenMessage([...array]);
                  }}
                >
                  <Paper class="documents-view-popper-holder">
                    {row.messages_list.map((message) => (
                      <div className="documents-view-message-holder">
                        <div className="documents-view-message-row1">
                          <div>{message.channel_type}</div>
                          <div>{message.content_type}</div>
                          <div>{message.status}</div>
                        </div>
                        <div
                          className="documents-view-message-row2"
                          onClick={() => openLink(message.destination)}
                        >
                          {message.destination}
                        </div>
                        <div className="documents-view-message-row3">
                          {message.display_text}
                        </div>
                        <div className="documents-view-message-row4">
                          {message.last_modified}
                        </div>
                      </div>
                    ))}
                  </Paper>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      ))}
    </>
  );
});

export default EpochTable;
