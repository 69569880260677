import React, { useCallback, useState, useRef } from "react";
import particlesConfig from "config/particle-config";
import particlesBlackConfig from "config/pr-s-black";
import Particles from "react-tsparticles";
import EpochTable from "./EpochTable";
import { loadFull } from "tsparticles";
import "./DocumentView.css";
import AlertsView from "./alertsView/AlertsView";
import EditNotificationsIcon from "@mui/icons-material/EditNotifications";
import UploadFile from "./uploadFile/UploadFile";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import ConstructionIcon from "@mui/icons-material/Construction";
import DownloadIcon from "@mui/icons-material/Download";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import { getDocuments } from "./Api";
import { formatDateForCell, toastSetting } from "helpers";
import InnerLoader from "components/Auth/inner-loader";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function DocumentsView({ blackStar }) {
  const [alertsOpen, setAlertsOpen] = useState(false);
  const [uploadOpen, setUploadOpen] = useState(false);
  const [actionsOpen, setActionsOpen] = useState(false);
  const [isDownloading, setIsDownloading] = useState(false);

  const epochTableRef = useRef(null);

  const particlesInit = useCallback(async (engine) => {
    await loadFull(engine);
    document
      .querySelector("#particles-js canvas")
      ?.style.removeProperty("position");
  }, []);

  async function downloadFile(FileType) {
    const ref = epochTableRef.current;
    const payload = {
      search_text: ref.search,
      limit: ref.rowsPerPage,
      offset: ref.rowsPerPage * ref.page,
      ticker: ref.filters.ticker,
      ...FileType,
    };

    if (
      ref.filters?.dateRange[0]?.startDate &&
      ref.filters?.dateRange[0]?.endDate
    ) {
      payload.start_datetime = formatDateForCell(
        ref.filters?.dateRange[0]?.startDate
      );
      payload.end_datetime = formatDateForCell(
        ref.filters?.dateRange[0]?.endDate
      );
    }

    setIsDownloading(true);
    await getDocuments(payload)
      .then((data) => {
        const downloadLink = data?.export_xlsx_link ?? data?.export_csv_link;
        if (downloadLink) {
          const link = document.createElement("a");
          link.href = downloadLink;
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        }
      })
      .catch(() => {
        toast.error("Download failed!", toastSetting);
      });
    setIsDownloading(false);
  }

  function downloadCsvFile() {
    downloadFile({ export_csv: true });
  }

  function downloadXlxsFile() {
    downloadFile({ export_xlsx: true });
  }

  return (
    <header className=" circle-bg valign documents-view">
      <ToastContainer />

      <InnerLoader
        loading={isDownloading}
        message={"Downloading..."}
        relative={true}
        zIndex={100}
      />
      <div className="container documents-view-wrapper">
        <div className="documents-view-row">
          <h1 class="wow color-font animated">Documents</h1>
          <div className="documents-view-button-holder">
            <Button
              variant="outlined"
              className="document-view-desktop-button"
              component="span"
              sx={{
                color: "white",
                borderColor: "white",
                p: 1.2,
                fontSize: "0.8rem",
                "&:hover": {
                  borderColor: "white",
                  backgroundColor: "rgba(255, 255, 255, 0.2)",
                },
              }}
              onClick={() => setUploadOpen(true)}
            >
              <UploadFileIcon sx={{ fontSize: "26px" }} />
              Upload Document
            </Button>
            <Button
              variant="outlined"
              className={`document-view-desktop-button-actions ${
                actionsOpen && "block-hover-effect"
              }`}
              component="span"
              sx={{
                color: "white",
                borderColor: "white",
                p: 1.2,
                fontSize: "0.8rem",
                "&:hover": {
                  borderColor: "white",
                  backgroundColor: "rgba(255, 255, 255, 0.2)",
                },
              }}
              onClick={() => setActionsOpen(!actionsOpen)}
            >
              <ConstructionIcon sx={{ fontSize: "26px" }} />
              Actions
              {actionsOpen && (
                <ClickAwayListener onClickAway={() => setActionsOpen(false)}>
                  <div className="documents-view-dropdown-menu-actions">
                    <IconButton
                      className="dropdown-menu-actions"
                      style={{ color: "white" }}
                      onClick={() => setAlertsOpen(true)}
                    >
                      <EditNotificationsIcon />
                      Edit Alerts
                    </IconButton>

                    <IconButton
                      className="dropdown-menu-actions"
                      style={{ color: "white" }}
                      onClick={() => downloadCsvFile()}
                    >
                      <DownloadIcon />
                      Export CSV
                    </IconButton>
                    <IconButton
                      className="dropdown-menu-actions"
                      style={{ color: "white" }}
                      onClick={() => downloadXlxsFile()}
                    >
                      <DownloadIcon />
                      Export Excel
                    </IconButton>
                  </div>
                </ClickAwayListener>
              )}
            </Button>

            <UploadFileIcon
              fontSize="large"
              className="documents-view-alerts-button"
              onClick={() => setUploadOpen(true)}
            />
            <div className="documents-view-alerts-button-holder">
              <ConstructionIcon
                fontSize="large"
                className="documents-view-alerts-button"
                onClick={() => setActionsOpen(!actionsOpen)}
              />
              {actionsOpen && (
                <ClickAwayListener onClickAway={() => setActionsOpen(false)}>
                  <div className="documents-view-dropdown-menu-actions">
                    <IconButton
                      className="dropdown-menu-actions"
                      style={{ color: "white" }}
                      onClick={() => setAlertsOpen(true)}
                    >
                      <EditNotificationsIcon />
                      Edit Alerts
                    </IconButton>

                    <IconButton
                      className="dropdown-menu-actions"
                      style={{ color: "white" }}
                      onTouchEnd={() => downloadCsvFile()}
                      onClick={() => downloadCsvFile()}
                    >
                      <DownloadIcon />
                      Export csv
                    </IconButton>

                    <IconButton
                      className="dropdown-menu-actions"
                      style={{ color: "white" }}
                      onTouchEnd={() => downloadXlxsFile()}
                      onClick={() => downloadXlxsFile()}
                    >
                      <DownloadIcon />
                      Export Excel
                    </IconButton>
                  </div>
                </ClickAwayListener>
              )}
            </div>
          </div>
        </div>
        <AlertsView alertsOpen={alertsOpen} setAlertsOpen={setAlertsOpen} />
        <UploadFile uploadOpen={uploadOpen} setUploadOpen={setUploadOpen} />

        <EpochTable ref={epochTableRef} />
      </div>

      <Particles
        id="particles-js"
        init={particlesInit}
        options={blackStar ? particlesBlackConfig : particlesConfig}
      />
      <div className="gradient-circle"></div>
      <div className="gradient-circle two"></div>
    </header>
  );
}

export default DocumentsView;
