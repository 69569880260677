import React, { useCallback, useState } from "react";
import particlesConfig from "config/particle-config";
import particlesBlackConfig from "config/pr-s-black";
import Particles from "react-tsparticles";
import { loadFull } from "tsparticles";
import "./TermsAndPrivacy.css";

function TermsView({ blackStar }) {
  const particlesInit = useCallback(async (engine) => {
    await loadFull(engine);
    document
      .querySelector("#particles-js canvas")
      ?.style.removeProperty("position");
  }, []);

  return (
    <header className=" circle-bg valign terms-view">
      <div className="container ">
        <h1 class="wow color-font animated terms-title">Terms of service</h1>

        <p>Last updated: May 16, 2024</p>

        <h2>Introduction</h2>
          
        <p>These terms and conditions ("Terms") govern your use of the 9epoch platform ("Platform"), operated by 9epoch Pty Ltd ("9epoch", "we", "us", "our"), ABN 78 637 136 844. By accessing or using the Platform, you agree to be bound by these Terms. If you do not agree with any part of these Terms, you must not use the Platform.</p>

        <h2>Intellectual Property Rights</h2>
        <p>The Platform and its original content, features, and functionality are owned by 9epoch and are protected by intellectual property laws. All rights, title, and interest in and to the Platform are and will remain the exclusive property of 9epoch.</p>

        <h2>Restrictions</h2>
        <p>You agree not to:</p>
        <ul>
            <li>Modify, adapt, translate, reverse engineer, decompile, disassemble or create derivative works based on the Platform;</li>
            <li>Use the Platform for any unlawful purpose or in any manner inconsistent with these Terms;</li>
            <li>Use the Platform to infringe upon or violate our intellectual property rights or the rights of others.</li>
        </ul>

        <h2>Your Content</h2>
        <p>By uploading or forwarding PDF and email text documents to the Platform, you grant us a non-exclusive, worldwide, royalty-free, sublicensable, and transferable license to use, reproduce, distribute, modify, adapt, display, and perform the content solely for the purpose of providing our services to you.</p>

        <h2>AI Analysis</h2>
        <p>The Platform utilizes artificial intelligence ("AI") algorithms to analyze uploaded documents and provide quantitative data related to investment and stock-specific information. While we strive for accuracy, AI technologies may occasionally generate errors or inaccuracies in the analysis. Therefore, the information provided by the Platform should be used for informational purposes only and not relied upon as the sole basis for investment decisions.</p>

        <h2>No Warranties</h2>
        <p>The Platform is provided "as is," without any representations or warranties of any kind, express or implied. We do not warrant that the Platform will be error-free or uninterrupted, or that any defects will be corrected. Any reliance on the Platform and its contents is at your own risk.</p>
        
        <h3>Factual Information Only</h3>
        <p>The information provided by 9epoch is factual information only. It does not contain any general advice or opinion.</p>

        <h3>No Personalized Advice</h3>
        <p>The information has been prepared without taking into account your objectives, financial situation, or needs. It is made available "as is," without any representation or warranty, either express or implied, being given as to the accuracy, completeness, timeliness, reliability, or otherwise of its content.</p>

        <h3>No Liability for Errors</h3>
        <p>No responsibility is accepted by us for any errors, omissions, or any inaccurate information provided to you in this document.</p>

        <h3>Not Investment or Legal Advice</h3>
        <p>The material provided to you is not intended to amount to or provide, constitute or comprise, investment, financial, legal, or other advice on any particular matter and no action should be taken or omitted to be taken in reliance on the information contained in this document. You should, before acting on any advice, seek financial advice from a suitably qualified professional in relation to any particular matter or circumstance.</p>

        <h3>Limitation of Liability</h3>
        <p>To the fullest extent permitted by law, we expressly exclude, and accept no liability for any loss or damage which may be suffered by you, or any third party, and/or which may arise from the use, or the inability to use, this document or the use of, or any reliance placed on, any information contained in this document, including, but not limited to direct, indirect, consequential or other loss or damage, loss of data, loss of income, profits, contracts or opportunity, loss of goodwill or claims by third parties and whether caused by tort (including negligence), breach of contract or otherwise, even if such loss or damage was reasonably foreseeable by us.</p>

        <ul>
            <li>Your use or inability to use the Platform;</li>
            <li>Any content or conduct of any third party on the Platform;</li>
            <li>Unauthorized access, use, or alteration of your transmissions or content.</li>
        </ul>

        <h2>Indemnification</h2>
        <p>You agree to indemnify and hold 9epoch and its affiliates, officers, agents, employees, and partners harmless from and against any claims, liabilities, damages, losses, and expenses, including reasonable legal fees and costs, arising out of or in any way connected with:</p>
        <ul>
            <li>Your access to or use of the Platform;</li>
            <li>Your violation of these Terms;</li>
            <li>Your violation of any rights of another party.</li>
        </ul>

        <h2>Severability</h2>
        <p>If any provision of these Terms is found to be invalid or unenforceable under applicable law, such provision shall be severed from these Terms without affecting the validity and enforceability of the remaining provisions.</p>

        <h2>Variation of Terms</h2>
        <p>9epoch reserves the right, at its sole discretion, to modify or replace these Terms at any time. It is your responsibility to review these Terms periodically for changes. Your continued use of the Platform following the posting of any changes to these Terms constitutes acceptance of those changes.</p>

        <h2>Assignment</h2>
        <p>9epoch may assign, transfer, or otherwise dispose of its rights and obligations under these Terms, in whole or in part, at any time without notice. You may not assign, transfer, or delegate your rights or obligations under these Terms without our prior written consent.</p>

        <h2>Entire Agreement</h2>
        <p>These Terms constitute the entire agreement between you and 9epoch regarding your use of the Platform and supersede all prior or contemporaneous agreements, communications, and proposals, whether oral or written, between you and 9epoch.</p>

        <h2>Governing Law & Jurisdiction</h2>
        <p>These Terms shall be governed by and construed in accordance with the laws of the Victoria, Australia, without regard to its conflict of law provisions. You agree to submit to the exclusive jurisdiction of the courts located in Victoria, Australia, to resolve any legal matter arising from these Terms.</p>
      </div>





      <Particles
        id="particles-js"
        init={particlesInit}
        options={blackStar ? particlesBlackConfig : particlesConfig}
      />
      <div className="gradient-circle"></div>
      <div className="gradient-circle two"></div>
    </header>
  );
}

export default TermsView;
