import React, { useState, useEffect } from "react";
import InnerLoader from "components/Auth/inner-loader";
import CloseIcon from "@mui/icons-material/Close";
import "./UploadFile.css";
import { styled } from "@mui/material/styles";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import Button from "@mui/material/Button";
import { uploadDocument } from "../Api";
import JSZip from "jszip";

const Input = styled("input")({
  display: "none",
});

function UploadFile({ setUploadOpen, uploadOpen }) {
  const [hide, setHide] = useState(false);
  const [loading, setLoading] = useState(false);
  const [file, setFile] = useState(null);
  const [uploaded, setUploaded] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    setFile(null);
    setUploaded(false);
    if (!uploadOpen) {
      setTimeout(() => {
        setHide(true);
      }, 300);
    } else {
      setHide(false);
    }
  }, [uploadOpen]);

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setLoading(true);
      if (file.name.endsWith(".zip")) {
        const reader = new FileReader();
        reader.onload = async (e) => {
          try {
            const zip = await JSZip.loadAsync(e.target.result);
            const allPDFs = Object.values(zip.files).every((file) =>
              file.name.endsWith(".pdf")
            );

            if (allPDFs) {
              // Proceed to upload or further processing
              setUploaded(true);
              setFile(file.name);
              setError(null);
            } else {
              setError("ZIP file must contain only PDF files.");
            }
          } catch (error) {
            setError("Error reading ZIP file: " + error.message);
          } finally {
            setLoading(false);
          }
        };
        reader.readAsArrayBuffer(file);
      } else {
        const reader = new FileReader();
        reader.onloadend = () => {
          const base64String = reader.result
            .replace("data:", "")
            .replace(/^.+,/, "");

          uploadDocument({ filename: file.name, document_data: base64String })
            .then(() => {
              setLoading(false);
              setUploaded(true);
              setFile(file.name);
            })
            .catch((error) => {
              setError("Upload failed: " + error);
              setLoading(false);
            });
        };
        reader.readAsDataURL(file);
      }
    }
  };

  return (
    <div
      className={`upload-view-backdrop ${
        !uploadOpen && "opacity-upload-view-backdrop"
      } ${hide && "hidden-upload-view-backdrop"}`}
    >
      <div className="upload-view-modal">
        <InnerLoader loading={loading} message="Uploading file..." />
        <CloseIcon
          className="upload-view-close-icon"
          fontSize="large"
          onClick={() => setUploadOpen(false)}
        />
        <h1 className="upload-view-title">Upload Document</h1>

        <label htmlFor="contained-button-file" className="upload-button">
          <Input
            accept=".pdf, .zip"
            id="contained-button-file"
            multiple
            type="file"
            onChange={handleFileChange}
          />
          <Button
            variant="outlined"
            className="upload-view-button"
            component="span"
            sx={{
              color: "white",
              borderColor: "white",
              p: 1.5,
              fontSize: "1rem",
              "&:hover": {
                borderColor: "white",
                backgroundColor: "rgba(255, 255, 255, 0.2)",
              },
            }}
          >
            <UploadFileIcon sx={{ fontSize: "32px" }} />
            Upload PDF
          </Button>
        </label>

        {error && <div> {error} </div>}
        {uploaded && (
          <div className="upload-view-file-name">{file} is uploaded!</div>
        )}
      </div>
    </div>
  );
}

export default UploadFile;
