import React from "react";
import Grow from "@mui/material/Grow";
import Popper from "@mui/material/Popper";
import FormControl from "@mui/material/FormControl";
import DatePicker from "./DatePicker";
import SearchIcon from "@mui/icons-material/Search";
import IconButton from "@mui/material/IconButton";
import InputBase from "@mui/material/InputBase";
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowLeftIcon from "@mui/icons-material/ArrowLeft";

import { useState, useRef } from "react";

function Filters({ filters, setFilters, getTableFilterData, clearFilters }) {
  const [open, setOpen] = useState(false);
  const anchorRef = useRef(null);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleDateChange = (dates) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      dateRange: dates,
    }));
  };

  const handleTickerSearch = (event) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      ticker: event.target.value,
    }));
  };

  return (
    <>
      <Button
        onClick={handleToggle}
        endIcon={open ? <ArrowDropDownIcon /> : <ArrowLeftIcon />}
        ref={anchorRef}
        className="documents-view-button "
      >
        Filters
      </Button>
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        placement="bottom"
        transition
        disablePortal
        className="documents-view-popper"
      >
        {({ TransitionProps }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin: "top",
            }}
          >
            <Paper class="documents-view-popper-module">
              <Paper class="documents-view-filters-holder">
                <div class="documents-view-filters-section">
                  <DatePicker
                    state={filters.dateRange}
                    setState={handleDateChange}
                  />

                  <FormControl>
                    <div
                      class="documents-view-input-label"
                      htmlFor="search-input"
                    >
                      Search by Ticker
                    </div>
                    <Paper class="documents-view-input">
                      <InputBase
                        placeholder="Search..."
                        onChange={handleTickerSearch}
                        value={filters.ticker || ""}
                      />
                      <IconButton
                        id="search-input"
                        type="button"
                        aria-label="search"
                      >
                        <SearchIcon />
                      </IconButton>
                    </Paper>
                  </FormControl>
                </div>
                <div class="documents-view-filters-actions">
                  <Button
                    onClick={clearFilters}
                    className="documents-view-button clear"
                  >
                    Clear
                  </Button>
                  <Button
                    onClick={getTableFilterData}
                    className="documents-view-button update"
                  >
                    Update
                  </Button>
                </div>
              </Paper>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  );
}

export default Filters;
