import React, { useCallback, useEffect, useRef, useState } from "react";
import Navbar from "components/Navbar/navbar";
import AuthComponent from "components/Auth/backend_auth";
import DarkTheme from "layouts/Dark";
import { useAuth } from "../../authContext";
import particlesConfig from "config/particle-config";
import particlesBlackConfig from "config/pr-s-black";
import Particles from "react-tsparticles";
import { loadFull } from "tsparticles";

const PlatformAuth = ({ blackStar, location }) => {
  const navbarRef = useRef(null);
  const logoRef = useRef(null);
  const { user, isLoading, isAuthenticated } = useAuth();
  const [isSignUp, setIsSignUp] = useState(false);

  const particlesInit = useCallback(async (engine) => {
    await loadFull(engine);
    document
      .querySelector("#particles-js canvas")
      ?.style.removeProperty("position");
  }, []);

  useEffect(() => {
    // Handle URL parameters for signup/signin
    const searchParams = new URLSearchParams(location.search);
    const signUpParam = searchParams.get("signup");
    setIsSignUp(signUpParam === "true");

    // Handle navbar scroll behavior
    const navbar = navbarRef.current;
    const handleScroll = () => {
      if (window.pageYOffset > 300) {
        navbar.classList.add("nav-scroll");
      } else {
        navbar.classList.remove("nav-scroll");
      }
    };

    window.addEventListener("scroll", handleScroll);

    // Cleanup function
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [location.search, navbarRef, isAuthenticated, user]);

  // If loading, you may want to display a loading indicator or similar
  if (isLoading) {
    return <div>Loading...</div>;
  }
  return (
    <DarkTheme>
      <Navbar nr={navbarRef} lr={logoRef} />
      <header className="circle-bg valign documents-view auth-view-circle-desktop">
        <div className="gradient-circle"></div>
      </header>
      <AuthComponent isSignUp={isSignUp} />
      <Particles
        id="particles-js"
        className="particles-background"
        init={particlesInit}
        options={blackStar ? particlesBlackConfig : particlesConfig}
      />
      <header className="circle-bg valign documents-view">
        <div className="gradient-circle two"></div>
      </header>
    </DarkTheme>
  );
}

export default PlatformAuth;