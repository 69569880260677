import React from "react";
import { AuthProvider } from "./authContext";
import PrivateRouteResolver from "./PrivateRouteResolver";
import smoothscroll from "smoothscroll-polyfill";

smoothscroll.polyfill();

export const shouldUpdateScroll = ({ routerProps: { location } }) => {
  const { hash } = location;
  if (hash) {
    setTimeout(() => {
      const element = document.querySelector(hash);
      if (element) {
        element.scrollIntoView({ behavior: "smooth", block: "start" });
      }
    }, 10);
    return false;
  }
  return true;
};

export const wrapPageElement = ({ element, props }) => {
  return (
    <PrivateRouteResolver location={props.location}>
      {element}
    </PrivateRouteResolver>
  );
};

export const wrapRootElement = ({ element, location }) => {
  return <AuthProvider location={location}>{element}</AuthProvider>;
};
