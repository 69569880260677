import React, { useState, useRef, useEffect } from "react";
import { Formik, Form, Field } from "formik";
import DarkTheme from "layouts/Dark";
import "./AuthComponent.css";
import Checkbox from "@mui/material/Checkbox";
import { Link } from "gatsby";
import SignupInformation from "./SignupInformation";
import { useAuth } from "../../../authContext";

const AuthComponent = ({ isSignUp: initialIsSignUp }) => {
  const {
    handleSignUp,
    handleSignIn,
    validateEmail,
    authErrorMessage,
    setAuthErrorMessage,
  } = useAuth();

  const [isSignUp, setIsSignUp] = useState(initialIsSignUp);
  const [signedUp, setSignedUp] = useState(false);
  const [termsAccepted, setTermsAccepted] = useState(false);
  const messageRef = useRef(null);

  const toggleForm = () => setIsSignUp(!isSignUp);

  useEffect(() => {
    setIsSignUp(initialIsSignUp);
    setAuthErrorMessage(null);
  }, [initialIsSignUp, setAuthErrorMessage]);

  return (
    <DarkTheme>
      <section className="contact section-padding auth-view">
        <div className="container">
          <div className="auth-view-holder">
            <div className="auth-view-wrapper">
              <div className="form md-mb50">
                {signedUp ? (
                  <>
                    <h4
                      className="fw-700 color-font mb-50"
                      style={{ textAlign: "center" }}
                    >
                      Registered
                    </h4>
                    <div className="auth-view-message">
                      Check your email for the activation link!
                    </div>
                  </>
                ) : (
                  <>
                    <h4 className="fw-700 color-font mb-50">
                      {isSignUp ? "Sign Up" : "Sign In"}
                    </h4>

                    <Formik
                      initialValues={{
                        email: "",
                        password: "",
                        fullName: "",
                        confirmPassword: "",
                      }}
                      onSubmit={async (values, { setSubmitting, resetForm }) => {
                        if (isSignUp) {
                          if (termsAccepted) {
                            const success = await handleSignUp(values);
                            if (success) setSignedUp(true);
                          } else {
                            setAuthErrorMessage("Please accept the terms.");
                          }
                        } else {
                          await handleSignIn(values);
                        }
                        setSubmitting(false);
                        resetForm();
                      }}
                    >
                      {({ errors, touched }) => (
                        <Form id="auth-form">
                          <div className="messages" ref={messageRef}></div>
                          <div className="controls">
                            {isSignUp && (
                              <div className="form-group">
                                <Field
                                  id="form_fullName"
                                  type="text"
                                  name="fullName"
                                  placeholder="Full Name"
                                  required="required"
                                />
                              </div>
                            )}
                            <div className="form-group">
                              <Field
                                validate={validateEmail}
                                id="form_email"
                                type="email"
                                name="email"
                                placeholder="Email"
                                autoComplete="email"
                              />
                              {errors.email && touched.email && (
                                <div>{errors.email}</div>
                              )}
                            </div>
                            <div className="form-group">
                              <Field
                                id="form_password"
                                type="password"
                                name="password"
                                placeholder="Password"
                                required="required"
                                autoComplete="current-password"
                              />
                            </div>
                            {isSignUp && (
                              <>
                                <div className="form-group">
                                  <Field
                                    id="form_confirmPassword"
                                    type="password"
                                    name="confirmPassword"
                                    placeholder="Confirm Password"
                                    required="required"
                                  />
                                </div>
                                <div className="form-group">
                                  <Checkbox
                                    checked={termsAccepted}
                                    onChange={(e) => setTermsAccepted(e.target.checked)}
                                    className="terms-checkbox"
                                  />{" "}
                                  I agree to the{" "}
                                  <Link className="terms-link" to="/terms">
                                    Terms of Service
                                  </Link>{" "}
                                  and{" "}
                                  <Link className="terms-link" to="/privacy">
                                    Privacy Policy
                                  </Link>
                                </div>
                              </>
                            )}
                          </div>
                          {authErrorMessage && (
                            <div className="error">{authErrorMessage}</div>
                          )}
                          {authErrorMessage && (
                            <div className="error">
                              Or contact support:{" "}
                              <a href="mailto:contact@9epoch.ai">
                                contact@9epoch.ai
                              </a>
                            </div>
                          )}

                          <div className="auth-buttons">
                            <button
                              type="submit"
                              className="butn bord curve wow fadeInUp"
                            >
                              <span>{isSignUp ? "Sign Up" : "Sign In"}</span>
                            </button>
                            <a
                              onClick={toggleForm}
                              className="butn bord curve wow fadeInUp"
                              style={{ marginLeft: "20px", cursor: "pointer" }}
                            >
                              <span>{isSignUp ? "Sign In" : "Sign Up"}</span>
                            </a>
                          </div>
                        </Form>
                      )}
                    </Formik>
                  </>
                )}
              </div>
            </div>
            {isSignUp && <SignupInformation />}
          </div>
        </div>
      </section>
    </DarkTheme>
  );
};

export default AuthComponent;