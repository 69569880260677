const url = `${process.env.GATSBY_BACKEND_API_BASE}`;

const getHeaders = () => {
  if (typeof window !== "undefined") {
    return {
      "Content-Type": "application/json",
      "X-API-KEY": process.env.GATSBY_BACKEND_API_KEY,
      access_token: localStorage.getItem("access_token9e"),
      refresh_token: localStorage.getItem("refresh_token9e"),
    };
  }
  return {
    "Content-Type": "application/json",
    "X-API-KEY": process.env.GATSBY_BACKEND_API_KEY,
  };
};

export const getDocuments = async (payload) => {
  const endpoint = url + "/documents/list";
  try {
    const headers = getHeaders();
    const response = await fetch(endpoint, {
      method: "POST",
      credentials: "include",
      headers: headers,
      body: JSON.stringify(payload ?? {}),
    });

    if (!response.ok) {
      throw new Error("Fetch failed");
    }

    const data = await response.json();
    return data;
  } catch (error) {
    throw new Error(error);
  }
};

export const getDocument = async (id) => {
  const endpoint = url + "/documents/detail";
  try {
    const headers = getHeaders();
    const response = await fetch(endpoint, {
      method: "POST",
      credentials: "include",
      headers: headers,
      body: JSON.stringify({ id: id }),
    });

    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Fetch error:", error);
  }
};

export const getUserDetails = async (id) => {
  const endpoint = url + "/users/detail";
  try {
    const headers = getHeaders();
    const response = await fetch(endpoint, {
      method: "POST",
      credentials: "include",
      headers: headers,
      body: JSON.stringify({ id: id }),
    });

    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Fetch error:", error);
  }
};

export const updateUserDetails = async (settings) => {
  const endpoint = url + "/users/update";
  try {
    const headers = getHeaders();
    const response = await fetch(endpoint, {
      method: "POST",
      credentials: "include",
      headers: headers,
      body: JSON.stringify({ settings: { ALERT_SETTINGS: settings } }),
    });

    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Fetch error:", error);
  }
};

export const uploadDocument = async (fileData) => {
  const endpoint = url + "/documents/upload";
  try {
    const headers = getHeaders();
    const response = await fetch(endpoint, {
      method: "POST",
      credentials: "include",
      headers: headers,
      body: JSON.stringify(fileData),
    });

    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Fetch error:", error);
  }
};
