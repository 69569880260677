import React from "react";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import Tooltip from "@mui/material/Tooltip";

function EpochTooltip({ tooltip, label }) {
  return (
    <Tooltip
      title={tooltip}
      placement="top"
      arrow
      className="documents-view-tooltip"
    >
      {label}
      <HelpOutlineIcon fontSize="small" className="epoch-tooltip-icon" />
    </Tooltip>
  );
}

export default EpochTooltip;
