import React from "react";
import "./pricingcard.css";
import { Link } from "gatsby";

function PricingCard({
  planName,
  subtitle,
  price,
  features,
  period,
  button,
  toLink,
  bestDeal = false,
}) {
  return (
    <div className={`pricing-card ${bestDeal && "best-deal"} wow fadeInUp`}>
      <div className="pricing-card-header">
        <h3 className="pricing-card-title">{planName}</h3>
        <p className="pricing-card-subtitle">{subtitle}</p>
      </div>
      <div className="pricing-card-body">
        <div className="pricing-card-price">
          <span className="pricing-card-price-amount">{price}</span>
          <span className="pricing-card-price-period">{period}</span>
        </div>
        <Link
          to={toLink}
          className={`pricing-butn butn bord curve mt-40 wow  ${
            bestDeal && "best-deal-butn"
          }`}
          data-wow-delay=".4s"
          style={{ position: "relative", zIndex: 1 }}
        >
          <span>{button}</span>
        </Link>
        <ul className="pricing-card-list">
          {features.map((feature, index) => (
            <li key={index}>{feature}</li>
          ))}
        </ul>
      </div>
    </div>
  );
}

export default PricingCard;
