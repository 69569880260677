import React from "react";
import "./SignupInformation.css";

function SignupInformation() {
  return (
    <div className="auth-view-wrapper signup-information-wrapper">
      <div className="form md-mb50">
        <span className="highlight"> Too much sell side research to read?</span>{" "}
        
        9epoch.ai can read & analyse all of your
        reports automatically.
        <ul className="signup-information-list">
        <li>
            <span className="highlight">
              {" "}
              Sign up for a free trial and receive 5 documents for free.
            </span>
          </li>
          <li>
            Market impact filters send you realtime 1 liners for the most
            important research (teams, slack, sms, email).
          </li>
          <li>
            1 page summaries condense hundred page reports into an objective &
            standardised short format.
          </li>
          <li>
            All your research remains private in your own searchable & secure
            database, filter by stock, broker, keywords.
          </li>
          <li>
            Perform quantitative research by exporting to excel and analysing
            hundreds of pre-calculated fields such as returns, volumes,
            sentiment & deep language features.
          </li>
          <li>
            Integration is easy, all you need to do is forward any documents you
            like to your own private & secure ai inbox. The ai platform will
            automatically reply back to you and populate your database.
          </li>
          
          <li>
            Our pricing starts at <u>90c per document</u> once the trial ends.
          </li>
        </ul>
      </div>
    </div>
  );
}

export default SignupInformation;
