import React from "react";
import Navbar from "components/Navbar/navbar";
import CallToAction from "components/Call-to-action/call-to-action";
import Footer from "components/Footer/footer";
import Intro4 from "components/Intro4/intro4";
import AboutUs3 from "components/About-us3/about-us3";
import Services3 from "components/Services3/services3";
import DarkTheme from "layouts/Dark";
import Numbers from "components/Numbers/numbers";
import { useAuth } from "../../authContext"; // Make sure the path is correct
import Faq from "components/Faq/faq";
import Pricing from "components/Pricing/Pricing";

const Homepage5 = () => {
  const navbarRef = React.useRef(null);
  const logoRef = React.useRef(null);
  const { checkAuthentication } = useAuth();

  React.useEffect(() => {
    checkAuthentication(); // Check if the user is authenticated, load user data and refresh token (if user has auth cookie)

    // Only run authentication check on the client-side
    if (typeof window !== "undefined") {
      const navbar = navbarRef.current;
      const handleScroll = () => {
        if (window.pageYOffset > 300) {
          navbar.classList.add("nav-scroll");
        } else {
          navbar.classList.remove("nav-scroll");
        }
      };

      // Initial check for the scroll position
      handleScroll();

      // Set up scroll event listener
      window.addEventListener("scroll", handleScroll);

      // Clean up function to remove the event listener
      return () => window.removeEventListener("scroll", handleScroll);
    }
  }, [navbarRef]);
  React.useEffect(() => {
    var navbar = navbarRef.current;

    if (window.pageYOffset > 300) {
      navbar.classList.add("nav-scroll");
    } else {
      navbar.classList.remove("nav-scroll");
    }

    window.addEventListener("scroll", () => {
      if (window.pageYOffset > 300) {
        navbar.classList.add("nav-scroll");
      } else {
        navbar.classList.remove("nav-scroll");
      }
    });
  }, [navbarRef]);

  return (
    <DarkTheme>
      <Navbar nr={navbarRef} lr={logoRef} homepage={true} />
      <Intro4 />
      <Services3 />

      <Pricing />
      <Faq />

      <AboutUs3 />
      <Numbers />
      <CallToAction />
      <Footer />
    </DarkTheme>
  );
};

export const Head = () => {
  return <title>9epoch - alpha signals platform</title>;
};

export default Homepage5;
